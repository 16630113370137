
import Country from "@/models/Country";
import LanguageGlobalSelectionModule from "@/store/modules/LanguageGlobalSelectionModule";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import availableCountries from "@/data/countries";

export default Vue.extend({
  name: "LocaleSwitcher",

  data() {
    return {
      countries: availableCountries,

      menu: false,
      selectedCountry: 0,
      languageGlobalSelectionModule: getModule(
        LanguageGlobalSelectionModule,
        this.$store
      ),
    };
  },

  created() {
    if (
      localStorage.getItem("lang") === "undefined" ||
      !localStorage.getItem("lang")
    ) {
      if (!Vue.$keycloak.idTokenParsed.locale)
        this.$store.commit("setLanguage", "de");
      else if (Vue.$keycloak.idTokenParsed.locale === "en")
        this.$store.commit("setLanguage", "en-GB");
      else
        this.$store.commit("setLanguage", Vue.$keycloak.idTokenParsed.locale);
    }
    this.$i18n.locale = this.languageGlobalSelectionModule.selectedLanguage;

    const locale = this.$i18n.locale;

    this.selectedCountry = this.countries.findIndex(
      (elem) => elem.locale == locale
    );
  },

  watch: {
    selectedCountry() {
      const country = this.countries.find(
        (elem, index) => index == this.selectedCountry
      );
      this.setLang(country?.locale);
      this.$store.commit("setLanguage", country?.locale);
    },
  },

  methods: {
    setLang(lang: any) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
  },

  computed: {
    currentLangugeLocale(): any {
      const locale = this.$i18n.locale;
      return this.countries.find((elem: Country) => elem.locale == locale);
    },
  },
});
