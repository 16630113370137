
import Vue from "vue";
import LogoTitle from "@/components/header/LogoTitle.vue";
import LocaleSwitcher from "@/components/header/LocaleSwitcher.vue";
import nav from "@/data/navigation";
import ComponentVersionModule from "@/store/modules/ComponentVersionModule";
import { getModule } from "vuex-module-decorators";

export default Vue.extend({
  name: "AppBarMobile",

  props: ["bgClass"],

  components: {
    LogoTitle,
    LocaleSwitcher,
  },

  data() {
    return {
      extended: false,
      backendVersion: "",
      frontendVersion: "",
      backendComponentVersion: getModule(ComponentVersionModule, this.$store),

      nav: nav,
      showTransitions: false,
    };
  },

  mounted() {
    if (Vue.$keycloak.authenticated) {
      this.backendComponentVersion
        .getBackendComponentVersionAction()
        .then(() => {
          this.backendVersion = this.backendComponentVersion.getVersion;
        })
        .catch(() => {
          this.backendVersion = "";
        });
    }
    this.frontendVersion = process.env.VUE_APP_VERSION;
    setTimeout(() => {
      this.showTransitions = true;
    }, 100);
  },

  methods: {
    logout() {
      Vue.$keycloak.logout();
    },

    onNavClick(item: any) {
      if (item.key == "logout") {
        this.logout();
      } else {
        this.extended = false;
      }
    },
  },

  computed: {
    firstname: function () {
      const givenName = Vue.$keycloak.idTokenParsed?.given_name;
      return givenName ? givenName : "Test";
    },

    avatar: function () {
      const givenName = Vue.$keycloak.idTokenParsed?.given_name;
      const familyName = Vue.$keycloak.idTokenParsed?.family_name;
      return givenName || familyName
        ? givenName.charAt(0) + familyName.charAt(0)
        : "TN";
    },
  },
});
