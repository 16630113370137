
import Vue from "vue";
export default Vue.extend({
  data: () => ({ fab: false }),
  methods: {
    onScroll(e: any) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
});
