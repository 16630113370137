
import Vue from "vue";
import AppBar from "@/components/header/AppBar.vue";
import AppBarMobile from "@/components/header/AppBarMobile.vue";
import HomeFooter from "@/components/footer/Footer.vue";
import UserFeedback from "@/components/UserFeedback.vue";
import { getModule } from "vuex-module-decorators";
import HomeConfigurationDataModule from "./store/modules/HomeConfigurationDataModule";
import ScrollUpButton from "@/components/base/ScrollUpButton.vue";

export default Vue.extend({
  name: "App",

  components: {
    AppBar,
    AppBarMobile,
    UserFeedback,
    HomeFooter,
    ScrollUpButton,
  },

  data() {
    return {
      bgClass: "transparent-bg",
      textColor: "white--text",
      theme: "",

      homeConfigurationData: getModule(
        HomeConfigurationDataModule,
        this.$store
      ),

      showFooterTransition: false,
    };
  },

  mounted() {
    let scrolledTo = document.querySelector(".home-Footer");
    window.onscroll = () => {
      this.changeColor();
      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.showFooterTransition = true;
      }
    };
    if (
      this.$vuetify.breakpoint.smAndDown ||
      this.isScrolledIntoView(scrolledTo)
    ) {
      this.showFooterTransition = true;
    }
    this.getThemeFromServer();
  },

  computed: {
    backgroundStyle(): any {
      return {
        backgroundImage: `url(${this.theme}) !important`,
        backgroundAttachment: "fixed !important",
        backgroundRepeat: "no-repeat !important",
        backgroundSize: "cover !important",
        backgroundPosition: "center center !important",
      };
    },
  },

  methods: {
    getThemeFromServer(): void {
      let theme = "";
      if (Vue.$keycloak.authenticated) {
        this.homeConfigurationData
          .getModuleDataAction()
          .then(() => {
            theme = this.homeConfigurationData.configurationData.theme;
            this.theme = theme;
          })
          .catch(() => {
            this.theme = theme;
          });
      } else {
        this.theme = theme;
      }
    },
    isScrolledIntoView(element: any) {
      let rect = element.getBoundingClientRect();
      let elemTop = rect.top;
      let elemBottom = rect.bottom;

      return elemTop < window.innerHeight && elemBottom >= 0;
    },

    changeColor(): void {
      if (
        document.body.scrollTop > 40 ||
        document.documentElement.scrollTop > 40
      ) {
        this.bgClass = "white";
        this.textColor = "grey--text text--darken-4";
      } else {
        this.bgClass = "transparent-bg";
        this.textColor = "white--text";
      }
    },
  },
});
