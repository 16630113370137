
import Vue from "vue";
export default Vue.extend({
  // This component describes the footer component, shown below every content page.

  name: "HomeFooter",
  props: ["showFooterTransition"],

  data: () => ({
    currentYear: new Date().getFullYear(),
  }),

  methods: {
    navigateToLink() {
      window.open("https://www.computacenter.com", "_blank");
    },
  },

  computed: {
    ccLogo() {
      return (extension: string) =>
        `${process.env.BASE_URL}ccLogo.${extension}`;
    },
    showFooterTransitions() {
      return this.showFooterTransition;
    },
  },
});
