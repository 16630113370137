
import Vue from "vue";
import AdminUserGlobalState from "@/store/modules/AdminUserGlobalState";
import { getModule } from "vuex-module-decorators";
import ExternalConfigModule from "@/store/modules/ExternalConfigModule";
import ComponentVersionModule from "@/store/modules/ComponentVersionModule";

export default Vue.extend({
  // This component describes the main navigation user info component, shown above every content page.

  name: "UserInfo",

  props: ["textColor"],

  data() {
    return {
      isAdminAndLargeScreen: false,
      isAdminLayout: false,
      menu: false,
      backendVersion: "",
      frontendVersion: "",

      backendComponentVersion: getModule(ComponentVersionModule, this.$store),
      adminUserGlobalState: getModule(AdminUserGlobalState, this.$store),
      externalConfigModule: getModule(ExternalConfigModule, this.$store),
    };
  },

  mounted() {
    this.isAdminAndLargeScreen =
      Vue.$keycloak.hasResourceRole("admin") && this.$vuetify.breakpoint.mdAndUp
        ? true
        : false;

    this.isAdminLayout = this.adminUserGlobalState.isAdminUserActive;

    if (Vue.$keycloak.authenticated){
      this.backendComponentVersion.getBackendComponentVersionAction().then(() => {
        this.backendVersion = this.backendComponentVersion.getVersion;
      }).catch(()=>{
        this.backendVersion="";
      });
    }
    this.frontendVersion = process.env.VUE_APP_VERSION;
  },

  methods: {
    changeLayout() {
      switch (this.isAdminLayout) {
        case true:
          this.isAdminLayout = false;
          break;
        case false:
          this.isAdminLayout = true;
          break;
      }
      this.$store.commit("setAdminStateMode", this.isAdminLayout);
    },

    logout() {
      this.menu = false;
      Vue.$keycloak.logout();
    },
  },

  computed: {
    firstname: function () {
      const givenName = Vue.$keycloak.idTokenParsed?.given_name;
      return givenName ? givenName : "";
    },
    lastname: function () {
      const familyName = Vue.$keycloak.idTokenParsed?.family_name;
      return familyName ? familyName : "";
    },
    avatar: function () {
      const givenName = Vue.$keycloak.idTokenParsed?.given_name;
      const familyName = Vue.$keycloak.idTokenParsed?.family_name;
      return givenName || familyName
        ? givenName.charAt(0) + familyName.charAt(0)
        : "";
    },
    roles() {
      const keycloakConfig: any = this.externalConfigModule.externalConfig;
      const clientId = keycloakConfig.KEYCLOAK_CLIENT_ID;
      const resourceAccess = Vue.$keycloak.resourceAccess
        ? Vue.$keycloak.resourceAccess[clientId]
        : null;
      return resourceAccess ? resourceAccess.roles : [];
    },
  },
});
