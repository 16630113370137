
import Vue from "vue";
import LogoTitle from "@/components/header/LogoTitle.vue";
import UserInfo from "@/components/header/UserInfo.vue";
import LocaleSwitcher from "@/components/header/LocaleSwitcher.vue";
import nav from "@/data/navigation";

export default Vue.extend({
  // This component describes the main navigation bar, or app-bar component, shown above every content page.

  name: "AppBar",

  props: ["bgClass", "textColor"],

  components: {
    LogoTitle,
    UserInfo,
    LocaleSwitcher,
  },

  data() {
    return {
      nav: nav,
      showTransitions: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showTransitions = true;
    }, 100);
  },

  methods: {
    hasRole(role: string) {
      return Vue.$keycloak.hasResourceRole(role);
    },
  },
});
